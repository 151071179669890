<app-header-tbs (navigate)="scrollTo($event)"></app-header-tbs>
<main>
  <app-hero-tbs></app-hero-tbs>
  <section id="benefits" class="container-tbs">
    <h2 (click)="scrollTo('benefits')">
      Beneficios del Total Body Scan <span>“Your Inside Selfie”</span>
    </h2>
    <div class="grid">
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-1.png"
          alt="Estudio Exhaustivo y No Invasivo"
          width="62px"
          height="62px"
        />
        <h6>Estudio Exhaustivo y No Invasivo</h6>
        <p>
          Nuestro TBS/YIS proporciona un completo análisis por imágenes del real
          estado de todos los órganos nobles, en menos de 30 minutos.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-2.png"
          alt="Tecnología de Última Generación"
          width="62px"
          height="62px"
        />
        <h6>Tecnología de Última Generación</h6>
        <p>
          Equipos recientemente instalados con la mejor tecnología y servicio de
          mantenimiento, de acuerdo con las normas de la Asociación Americana de
          Radiología.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-3.png"
          alt="Profesionales Expertos"
          width="62px"
          height="62px"
        />
        <h6>Profesionales Expertos</h6>
        <p>
          Radiólogos certificados con más de 25 años de experiencia en este tipo
          de estudios, técnicos radiólogos especialmente adiestrados y recursos
          humanos entrenados para atender pacientes internacionales.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-4.png"
          alt="Concierge Personalizado"
          width="62px"
          height="62px"
        />
        <h6>Concierge Personalizado</h6>
        <p>
          Servicio especializado en cuidados de salud. Asistente profesional
          acompaña al paciente en todo el proceso, desde la coordinación,
          realización del estudio, hasta la obtención del resultado, efectuando
          la orientación de los pasos a seguir.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-5.png"
          alt="Agenda Personalizada"
          width="62px"
          height="62px"
        />
        <h6>Agenda Personalizada</h6>
        <p>
          Nos adaptamos a sus necesidades y horarios, ofreciéndole agenda
          flexible para realizar el estudio cuando mejor le convenga.
        </p>
      </article>
      <article class="centered bottom-reveal">
        <img
          src="assets/landings/tbs/icons/benefits-icon-6.png"
          alt="Resultados Claros "
          width="62px"
          height="62px"
        />
        <h6>Resultados Claros</h6>
        <p>
          Recibirás un informe detallado con orientación médica personalizada.
        </p>
      </article>
    </div>
    <div class="grid" id="information">
      <div class="photo left-reveal">
        <img
          src="assets/landings/tbs/images/benefits-img-1.png"
          alt="Más Sobre el Total Body Scan"
        />
      </div>
      <article class="desc right-reveal">
        <h4>Más Sobre el Total Body Scan</h4>
        <p>
          El Total Body Scan es un estudio completo que brinda imágenes
          tridimensionales del real estado de todos los órganos nobles,
          permitiendo diagnosticar señales tempranas de alguna alteración
          patológica que pueda existir en el organismo. Es un procedimiento
          rápido, sencillo, no invasivo que permite tomar decisiones rápidas en
          caso de surgir alguna condición medica que lo requiera.
        </p>
        <h5>Principales beneficios:</h5>
        <ul>
          <li>
            <span>&rarr; Detección temprana:</span> Conocer el estado de los
            diferentes órganos del cuerpo, detectar en forma temprana diversas
            alteraciones que puedan existir como: malformaciones
            arterio-venosas, tumores, ganglios, quistes, cálculos, aneurismas,
            procesos degenerativos y más.
          </li>
          <li>
            <span>&rarr; Estudio no invasivo:</span> Duración aproximada de 20
            minutos, no existe contacto físico, uso de inyectables ni punciones.
            Solo se debe ingerir botellita de bario con gusto a vainilla una
            hora previa al estudio para poder imágenes con contraste del aparato
            digestivo.
          </li>

          <li>
            <span>&rarr; Resultados:</span> El paciente recibe en 24-48 horas un
            informe detallado órgano por órgano, mas el score da calcio de todas
            las arterias, con la traducción al español y la orientación mèdica
            personalizada (coaching).
          </li>
        </ul>
      </article>
    </div>
  </section>
  <section id="form-section">
    <div class="container-tbs">
      <div>
        <h2><span>¡Agenda</span> tu estudio!</h2>
        <p>
          Agenda tu Total Body Scan y toma medidas para asegurar tu bienestar
          futuro. Una vez realizada la compra, te contactaremos para coordinar
          tu estudio a la <span>brevedad.</span>
        </p>
        <p>
          Consulta con nuestros especialistas via whatsapp para aclarar
          cualquier duda en el momento.
        </p>
        <div class="ctas">
          <button class="button-animated">
            <a target="_self" href="https://square.link/u/vr769yh2">Comprar</a>
          </button>
        </div>
      </div>
      <form action="" class="right-reveal" [formGroup]="contactForm">
        <div [class]="{'submitted-message': true, show: this.submitted}">
          ¡Enviamos tu consulta! A la brevedad nos contactaremos contigo.
        </div>
        <h4>¡Contáctanos!</h4>
        <p>
          ¿Prefieres más información? Envíanos un correo y responderemos a la
          brevedad.
        </p>
        <input type="text" placeholder="Nombre" formControlName="nombre" />
        <input type="email" placeholder="Email" formControlName="email" />
        <textarea
          name="Mensaje"
          id="mensaje"
          placeholder="Mensaje"
          formControlName="notas"
        ></textarea>
        <div>
          <button
            class="button-animated"
            (click)="submitContactForm()"
            [disabled]="!isValid()"
          >
            Enviar consulta
          </button>
          <div class="separator"><span></span> o <span></span></div>

          <a
            href="https://api.whatsapp.com/send?phone=59899389189&type=phone_number&app_absent=0"
            target="_blank"
            >Escríbenos por Whatsapp &rarr;</a
          >
        </div>
      </form>
    </div>
  </section>
  <section id="professional-support" class="container-tbs">
    <h2 (click)="scrollTo('professional-support')">
      <span>Respaldo</span> profesional
    </h2>
    <div class="grid">
      <div class="ps-info">
        <div class="ps-info-item left-reveal">
          <div>
            <div class="icon-container">
              <img
                src="assets/landings/tbs/icons/professional-support-icon-1.png"
                alt="Respaldo Institucional"
              />
            </div>
            <h5>Respaldo Institucional:</h5>
          </div>
          <p>
            El servicio es realizado por Digital Medical Imaging (DMI), empresa
            de diagnóstico por imágenes con mas de 20 años de experiencia en la
            realización del TBS reconocida por su profesionalismo, tecnología de
            última generación y recursos humanos especializados.
          </p>
        </div>
        <div class="ps-info-item left-reveal">
          <div>
            <div class="icon-container">
              <img
                src="assets/landings/tbs/icons/professional-support-icon-2.png"
                alt="Servicio de Concierge Médico"
              />
            </div>
            <h5>Servicio de Concierge Médico:</h5>
          </div>
          <p>
            “Con MyTreat no está solo”. Un concierge profesional lo acompaña
            desde la reserva del estudio hasta la entrega de resultados,
            asegurándose que se sienta asistido y seguro.
          </p>
        </div>
      </div>
      <div class="photo-2 right-reveal">
        <img
          src="assets/landings/tbs/images/professional-support-img-1.png"
          alt="Professional Support"
        />
      </div>
    </div>
  </section>
  <section class="container-tbs" id="testimonials">
    <h2 (click)="scrollTo('testimonials')">Testimonios</h2>
    <div class="grid">
      <article class="bottom-reveal">
        <p>
          El equipo de MyTreat me hizo sentir cómoda y tranquila durante todo el
          proceso. El escaneo fue minucioso y eficiente, y los resultados fueron
          explicados de manera clara y concisa.
        </p>
        <p class="author">- Fátima, 71, Brasil</p>
      </article>
      <article class="bottom-reveal">
        <p>
          El total body scan fue el mejor regalo en años. Permite prevenir
          enfermedades viendo los puntos vulnerables. Mejor aún la atención de
          mytreat.health que brinda una atención personalizada.
        </p>
        <p class="author">– Martín, Argentina</p>
      </article>
      <article class="bottom-reveal">
        <p>
          Soy corredor de medio fondo amateur y el Total Body Scan me pareció el
          mejor chequeo para poder hacer deporte con tranquilidad. Realmente
          excelente. Sin contar que además es fácil reservar la hora y
          comprarlo.
        </p>
        <p class="author">– Leonardo, 54, Uruguay</p>
      </article>
    </div>
  </section>
</main>
<app-footer-tbs></app-footer-tbs>

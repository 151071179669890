import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {CategoryService, ProductService} from '@services';
import {combineLatest, Observable} from 'rxjs';
import {Category, Product} from '@models';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { filter, switchMap, map } from 'rxjs/operators';
import {MetaService} from '../../services/meta.service';
import {SearchBoxService} from '../../services/search-box.service';
import {environment} from '../../../environments/environment';
import {SearchService} from '../../services/search.service';
import { NumberLiteralType } from 'typescript';

@Component({
  selector: 'app-category-page',
  templateUrl: './category.page.html',
  styleUrls: []
})
export class CategoryPage implements OnInit {

  public categories$: Observable<Category[]>;
  public products$: Observable<Product[]>;
  public category: Category;
  public breadscrum: [Observable<Category>];
  public categoriesAux$: Observable<Category[]>;


  constructor(private titleService: Title,
              private productService: ProductService,
              private categoryService: CategoryService,
              private router: Router,
              private route: ActivatedRoute,
              private metaService: MetaService,
              private searchBoxService: SearchBoxService,
              private searchService: SearchService) {
    // @ts-ignore
    this.breadscrum = [];

  }

  ngOnInit() {
    let auxBreadscrum = this.router.url.split('/');
    if (auxBreadscrum.includes('total-body-scan')) this.router.navigateByUrl('total-body-scan/landing') //ESTE ARREGLO ES POR LA LANDING DEL TOTAL BODY SCAN
    auxBreadscrum = auxBreadscrum.slice(1, auxBreadscrum.length);
      auxBreadscrum.forEach((idExterno) => {
      this.breadscrum.push(this.categoryService.getOneDeepSearch$(idExterno));
    });
    this.searchBoxService.showSearchBox();
    this.categories$ = this.route.params.pipe(
      filter(data => data.category),
      switchMap(data => {
          if (data.thirdlevelcategory) {
            return this.categoryService.getChildrenDeepSearch$(data.thirdlevelcategory);
          } else if (data.subcategory) {
            return this.categoryService.getChildrenDeepSearch$(data.subcategory);
          }
          return this.categoryService.getChildrens$(data.category);
        }
      )
    );
    this.categoriesAux$ = this.categoryService.getAll$();

    this.route.params.pipe(
      filter(data => data.category),
      switchMap(data => this.categoryService.getOne$(data.category, data.subcategory)),
      filter(category => !!category)
    ).subscribe(category => {
      this.category = category;
      this.titleService.setTitle(environment.title + ' ' + category.nombre);
      this.metaService.setMetaCategory(category);
    });


    this.products$ = combineLatest([this.route.params, this.route.queryParams]).pipe(
      switchMap(([params, queryParams]) => {
          return this.productService.getProductsByCategoryWithOrder$(params.category, queryParams.tipo_orden, queryParams.ordenar, params.subcategory);
        }
      )
    );

  }
}

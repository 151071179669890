import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-footer-tbs",
  template: `
    <footer class="landing-footer">
      <h2><span>¡Agenda</span> tu estudio!</h2>
      <p class="landing-footer__desc italic">“Conocer el real estado de salud es lo más importante”</p>
      <p class="landing-footer__desc">
      Reserve su cita para realizar el Total Body Scan y obtener su <span>inside selfie </span>actualizada que permita asegurar el mejor pronóstico futuro de vida.</p>
      <button class="button-animated"><a target="_blank" href="https://square.link/u/vr769yh2">Comprar</a></button>
    </footer>
  `,
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-header-tbs",
  template: `
    <header class="d-none d-lg-block">
      <div class="container-tbs">
        <a href="https://www.mytreat.health/es">
          <img
            class="logo"
            src="assets/landings/tbs/images/logo.png"
            alt="logo"
          />
        </a>
        <nav>
          <ul>
            <li (click)="navigate.emit('benefits')">Beneficios</li>
            <li (click)="navigate.emit('information')">Información</li>
            <li (click)="navigate.emit('professional-support')">Respaldo</li>
            <li (click)="navigate.emit('testimonials')">Testimonios</li>
            <li class="button-animated">
              <a target="_self" href="https://square.link/u/vr769yh2"
                >Comprar</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </header>
  `,
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor() {}

  @Output() navigate: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}
}

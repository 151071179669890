import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NotificationDialog } from "./layouts/header/header.component";
import { NotificationsPage } from "./pages/notifications/notifications.page";
import { TruncateTextPipe } from "./utils/TruncateTextPipe.pipe";

import { LayoutModule } from "@angular/cdk/layout";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import {
  MatMenuModule,
  _MatMenuDirectivesModule,
} from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  FooterComponent,
  HeaderComponent,
  NormalLayoutComponent,
} from "@layouts";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConstantsUtils, MY_FORMATS } from "@utils";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { SwiperModule } from "swiper/angular";
import { initializer } from "./app-init";
import { AppRoutingModule } from "./app-routing.module";
import { CategorySliderComponent } from "./components/banner/banner-aplication/category-slider/category-slider.component";
import { ProductSliderComponent } from "./components/banner/banner-aplication/product-slider/product-slider.component";
import { CategoryListComponent } from "./components/category-list/category-list.component";
import {
  ComplementsListComponent,
  ScheduleableComplementDialog,
} from "./components/complements-list/complements-list.component";
import { ProductListItemComponent } from "./components/product-list/product-list-item/product-list-item.component";
import { ProductListComponent } from "./components/product-list/product-list.component";
import { SearchDialogComponent } from "./components/search-dialog/search-dialog.component";
import { CategoryPage } from "./pages/category/category.page";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { HomePage } from "./pages/home/home-page";
import { ServicesPage } from "./pages/orders/services/services.page";
import { ProductPage } from "./pages/product/product-page";
import { ProviderPage } from "./pages/provider/provider-page";
import {
  OrderMessageDialog,
  PurchasePage,
} from "./pages/purchase/purchase-page";
import { SearchPage } from "./pages/search/search-page";
import {
  AddUserDialog,
  CancelComplementDialog,
  CancelSubscriptionDialog,
  DeleteUserDialog,
  DetailPage,
  PurchaseComponentDialog,
} from "./pages/subscriptions/detail/detail.page";
import { SubscriptionsPage } from "./pages/subscriptions/subscriptions.page";
import { PaymentPage } from "./pages/user/payment/payment.page";
import { ConfirmSubDialog, UserPage } from "./pages/user/user.page";
import { SEARCH_DIALOG_COMPONENT } from "./services/dialog.tokens";
import { ResponseInterceptor } from "./shared-module/interceptors/ResponseInterceptor";
import { SafePipe } from "./utils/htmlParse";

import { DisclaimerPage } from "./pages/static/disclaimer/disclaimer-page";
import { PrivacyPage } from "./pages/static/privacy/privacy-page";
import { TermsPage } from "./pages/static/terms/terms-page";

// HTML Demo
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import localeESUY from "@angular/common/locales/es-UY";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { NavigationEnd, Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { BannerAplicacionComponent } from "./components/banner/banner-aplication/banner-aplicacion.component";
import { BannerCarruselComponent } from "./components/banner/banner-carrusel/banner-carrusel.component";
import { BannerImagenComponent } from "./components/banner/banner-imagen/banner-imagen.component";
import { BannerPersonalizadoComponent } from "./components/banner/banner-personalizado/banner-personalizado.component";
import { BannerTituloComponent } from "./components/banner/banner-titulo/banner-titulo.component";
import { BannerVideoComponent } from "./components/banner/banner-video/banner-video.component";
import { BannerComponent } from "./components/banner/banner.component";
import { WppBtnComponent } from "./components/wpp-btn/wpp-btn.component";
import { InvoicePage } from "./pages/invoice/invoice.page";
import { PaymentsPage } from "./pages/payments/payments.page";
import { AcceptModificationsComponent } from "./pages/purchase/accept-modifications/accept-modifications.component";
import { LangService } from "./services/lang.service";
import { AboutUsPage } from "./pages/about-us/about-us.page";
import { LandingsModule } from "./pages/static/landings/landings.module";

registerLocaleData(localeESUY);

function resolveLocale() {
  const defaultValue = "es-UY";
  if (
    typeof window === "undefined" ||
    typeof window.navigator === "undefined"
  ) {
    return defaultValue;
  }
  const wn = window.navigator as any;
  // let lang = wn.languages ? wn.languages[0] : defaultValue;
  // lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
  const langService = new LangService();
  const lang = langService.getLang();
  let finalLang = "";
  if (lang.startsWith("es")) {
    finalLang = "es-UY";
  } else {
    finalLang = "en-US";
  }
  return finalLang;
}

// const keycloakService: KeycloakService = new KeycloakService();

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    CategoryPage,
    ProductPage,
    CategoryListComponent,
    ProductListComponent,
    CategorySliderComponent,
    ProductSliderComponent,
    ProductListItemComponent,
    HeaderComponent,
    FooterComponent,
    NormalLayoutComponent,
    DashboardPage,
    DetailPage,
    SearchDialogComponent,
    SearchPage,
    PurchasePage,
    SubscriptionsPage,
    ServicesPage,
    PaymentPage,
    UserPage,
    NotificationsPage,
    SafePipe,
    OrderMessageDialog,
    ComplementsListComponent,
    PurchaseComponentDialog,
    CancelComplementDialog,
    CancelSubscriptionDialog,
    ConfirmSubDialog,
    NotificationDialog,
    TruncateTextPipe,
    ScheduleableComplementDialog,
    // HTML Demo
    DeleteUserDialog,
    AddUserDialog,
    InvoicePage,
    PaymentsPage,
    AcceptModificationsComponent,
    BannerComponent,
    BannerTituloComponent,
    BannerCarruselComponent,
    BannerImagenComponent,
    BannerVideoComponent,
    BannerAplicacionComponent,
    BannerPersonalizadoComponent,
    ProviderPage,
    DisclaimerPage,
    PrivacyPage,
    TermsPage,
    WppBtnComponent,
    AboutUsPage,
  ],
  imports: [
    LandingsModule,
    BrowserModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    NgbModule,
    MatCardModule,
    MatTabsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    _MatMenuDirectivesModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SwiperModule,
    CommonModule
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: resolveLocale() },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: SEARCH_DIALOG_COMPONENT, useValue: SearchDialogComponent },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    { provide: "googleTagManagerId", useValue: environment.googleTagManagerId },
    DatePipe,
    { provide: LOCALE_ID, useValue: resolveLocale() },

    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    injector: Injector,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    ConstantsUtils.injector = injector;

    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hero-tbs",
  template: `
    <div class="image-background"></div>
    <div class="gradient-background">
      <div class="container-tbs">
        <div class="title">
          <h1>Total Body Scan</h1>
          <h4>
            Your inside <span>selfie</span> <br />Descubre tu estado real de
            salud.
          </h4>
          <p>
            Completo estudio por imágenes 3D, no invasivo y con lo último en
            tecnología, que te brindara tranquilidad y seguridad.
          </p>
          <button class="button-animated">
            <a target="_self" href="https://square.link/u/vr769yh2"
              >Actúa ahora y reserva tu cita ya</a
            >
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import ScrollReveal from "scrollreveal";
import { AbstractPage } from "src/app/pages/abstract.page";
import { ContactFormService } from "src/app/services/contactForm.service";

@Component({
  selector: "app-total-body-scan-landing",
  templateUrl: "./total-body-scan.landing.html",
  styleUrls: ["./total-body-scan.landing.scss"],
})
export class TotalBodyScanLandingPage
  extends AbstractPage
  implements OnInit, AfterViewInit
{
  public contactForm = new FormGroup({
    nombre: new FormControl("", Validators.compose([Validators.required])),
    email: new FormControl(
      "",
      Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
      ])
    ),
    notas: new FormControl("", Validators.required),
  });

  loadingForm = false;
  submitted = false;

  constructor(private contactFormService: ContactFormService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    console.log("initializing scroll reveal");
    const sr = ScrollReveal();

    for (let i = 0; i < 6; i++) {
      sr.reveal(`.bottom-reveal:nth-child(${i + 1}) `, {
        duration: 500,
        origin: "bottom",
        distance: "75px",
        easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
        reset: false,
        mobile: false,
        delay: i * 200,
        viewOffset: {
          bottom: 100,
        },
      });
    }

    sr.reveal(`.left-reveal`, {
      duration: 500,
      origin: "left",
      distance: "75px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      delay: 200,
      mobile: false,
      viewOffset: {
        bottom: 100,
      },
    });

    sr.reveal(`.right-reveal`, {
      duration: 500,
      origin: "right",
      distance: "175px",
      easing: "cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      reset: false,
      delay: 200,
      mobile: false,
      viewOffset: {
        bottom: 100,
      },
    });
  }

  submitContactForm() {
    this.contactForm.markAllAsTouched();
    this.loadingForm = true;
    if (this.contactForm.valid) {
      this.contactFormService.send(this.contactForm.value).subscribe(() => {
        this.loadingForm = false;
        this.submitted = true;
        setTimeout(() => {
          this.submitted = false;
        }, 5000)
        this.contactForm.reset()
      });
    }
  }

  isValid() {
    return (
      this.contactForm.get("nombre").valid &&
      this.contactForm.get("email").valid &&
      this.contactForm.get("notas").valid
    );
  }

  pageTitle(): string {
    return "Total Body Scan";
  }

  scrollTo(id: string) {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: id === "benefits" ? "start" : "center",
    });
  }
}

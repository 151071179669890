<!-- {{element?.titulo}}
{{element?.linkValor}}
{{element?.tipoBanner}}
{{element?.imagen}}
{{element?.tipoVisualizacion}}
{{element?.ancho}}
{{element?.orden}}
{{element?.lenguaje}}
{{element?.fechaDesde | date:'shortDate'}}
{{element?.fechaFin | date:'shortDate'}} -->


<!-- DESKTOP d-none d-sm-block -->
<ngb-carousel interval="9000" pauseOnHover="true" showNavigationArrows="false" class="hero-slider d-none d-sm-block"
  [ngClass]="{'full-width': element?.ancho === 0}" *ngIf="element?.ancho === 0">
  <ng-container *ngFor="let item of element.itemCarrusel">
    <ng-template *ngIf="item?.tipoVisualizacion=='DESKTOP' || item?.tipoVisualizacion=='AMBOS'" ngbSlide>
      <a href="{{item?.linkValor}}">
        <img src="{{item?.imagen}}" alt="{{item?.titulo}}" />
      </a>
    </ng-template>
  </ng-container>
</ngb-carousel>


<!-- MOVIL  d-block d-sm-none-->
<ngb-carousel interval="9000" pauseOnHover="true" showNavigationArrows="false"
  class="hero-slider col-sm-12 p-2 text-center d-block d-sm-none">
  <ng-container *ngFor="let item of element.itemCarrusel">
    <ng-template *ngIf="item?.tipoVisualizacion=='MOVIL' || item?.tipoVisualizacion=='AMBOS'" ngbSlide>
      <a href="{{item?.linkValor}}">
        <img src="{{item?.imagen}}" alt="{{item?.titulo}}" />
      </a>
    </ng-template>
  </ng-container>
</ngb-carousel>
import { NgModule } from "@angular/core";
import { FooterComponent } from "./tbs/footer/footer.component";
import { CommonModule } from "@angular/common";
import { TotalBodyScanLandingPage } from "./tbs/total-body-scan.landing";
import { HeaderComponent } from './tbs/header/header.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HeroComponent } from './tbs/hero/hero.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    FooterComponent,
    TotalBodyScanLandingPage,
    HeaderComponent,
    HeroComponent,
  ],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule,RouterModule],
  exports: [TotalBodyScanLandingPage]
})
export class LandingsModule{

}

import { Component } from "@angular/core";
import { SlideInOutAnimation } from "./utils/animations";
import { LoginService } from "./components/login-service";
import { DialogService } from "./services/dialog.service";
import { environment } from "src/environments/environment";
import { NotificationService } from "./services/notification.service";
import { Observable } from "rxjs";
import { Category } from "@models";
import { CategoryService } from "@services";
import { LangService } from "./services/lang.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [],
  animations: [SlideInOutAnimation],
})
export class AppComponent {
  public categories$: Observable<Category[]>;
  public categoriesAux$: Observable<Category[]>;
  user: Keycloak.KeycloakProfile;
  badgeContent: number;
  notifications: Observable<Notification[]>;
  navigationSubState: { [menu: string]: string } = {
    Categories: "active",
    Manage: "inactive",
    User: "inactive",
  };
  public isEnglish: boolean;
  wpp: string;
  isLanding: boolean;

  toggleNavigationSub(menuName: string, event: Event) {
    event.preventDefault();
    this.navigationSubState[menuName] =
      this.navigationSubState[menuName] === "inactive" ? "active" : "inactive";
  }

  logOut() {
    this.loginService.logOut(environment.urlApp);
  }

  openDialog() {
    this.dialogService.open();
  }

  login() {
    this.loginService.login();
  }

  constructor(
    private categoryService: CategoryService,
    private loginService: LoginService,
    private dialogService: DialogService,
    public notificationService: NotificationService,
    private langService: LangService,
    public route: ActivatedRoute
  ) {
    this.isEnglish = this.langService.getLang() === "en";
    console.log(this.langService.getLang());

    this.isLanding = location.pathname.split("/").includes("landing");
    console.log(this.isLanding);
    this.categories$ = this.categoryService.getAll$();

    if (environment.phoneNumber !== undefined) {
      this.wpp = environment.phoneNumber;
    }
    this.loginService.user$.subscribe((user) => {
      this.user = user;
      if (user != null) {
        let path =
          environment.apiBase +
          "/aplicaciones/" +
          environment.keycloakConfig.realm +
          "/tenants/" +
          environment.keycloakConfig.clientId +
          "/notificaciones/usuarioWeb?desplazamiento=0&limite=5&oculto=false";
        this.notificationService.obtenerTodos(path).subscribe((data) => {
          this.notifications = data.notificaciones;
          this.badgeContent = data.total;
        });
      }
    });
  }

  changeLanguage(language: string) {
    window.location.href = environment.urlApp + language;
  }
}

import { InvoicesService } from "./services/invoices.service";
import { RouterModule, Routes } from "@angular/router";
import { HomePage } from "./pages/home/home-page";
import { CategoryPage } from "./pages/category/category.page";
import { ProductPage } from "./pages/product/product-page";
import { AppAuthGuard } from "./components/app-auth.guard";
import { NgModule } from "@angular/core";
import { SearchPage } from "./pages/search/search-page";
import { PurchasePage } from "./pages/purchase/purchase-page";
import { SubscriptionsPage } from "./pages/subscriptions/subscriptions.page";
import { ServicesPage } from "./pages/orders/services/services.page";
import { PaymentPage } from "./pages/user/payment/payment.page";
import { UserPage } from "./pages/user/user.page";
import { DetailPage } from "./pages/subscriptions/detail/detail.page";
import { NotificationsPage } from "./pages/notifications/notifications.page";
import { ProviderPage } from "./pages/provider/provider-page";
import { AboutUsPage } from "./pages/about-us/about-us.page";

import { DisclaimerPage } from "./pages/static/disclaimer/disclaimer-page";
import { PrivacyPage } from "./pages/static/privacy/privacy-page";
import { TermsPage } from "./pages/static/terms/terms-page";

// HTML Demo
import { InvoicePage } from "./pages/invoice/invoice.page";
import { PaymentsPage } from "./pages/payments/payments.page";
import { InvoicesResolver } from "./pages/invoice/invoices.resolver";
import { TotalBodyScanLandingPage } from "./pages/static/landings/tbs/total-body-scan.landing";

const routes: Routes = [
  {
    path: "total-body-scan/landing",
    pathMatch: "full",
    component: TotalBodyScanLandingPage
  },
  // {
  //   path: "total-body-scan",
  //   outlet: "landings",
  //   component: TotalBodyScanLandingPage,
  // },
  {
    path: "",
    component: HomePage,
  },
  {
    path: "provider",
    component: ProviderPage,
  },
  {
    path: "disclaimer",
    component: DisclaimerPage,
  },
  {
    path: "privacy",
    component: PrivacyPage,
  },
  {
    path: "terms",
    component: TermsPage,
  },
  {
    path: "about-us",
    component: AboutUsPage,
  },
  { path: "search/:search", component: SearchPage },
  {
    path: "invoice",
    component: InvoicePage,
    resolve: {
      invoices: InvoicesResolver,
    },
    runGuardsAndResolvers: "pathParamsOrQueryParamsChange",
    canActivate: [AppAuthGuard],
  },
  {
    path: "payments",
    component: PaymentsPage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "user",
    component: UserPage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "notifications",
    component: NotificationsPage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "user/payment",
    component: PaymentPage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "ordenes/servicios",
    component: ServicesPage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "subscriptions",
    component: SubscriptionsPage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "subscriptions/:subscriptionId/detail",
    component: DetailPage,
    canActivate: [AppAuthGuard],
  },
  { path: "product/:product", component: ProductPage },
  {
    path: "product/:product/purchase",
    component: PurchasePage,
    canActivate: [AppAuthGuard],
  },
  {
    path: "product/:product/purchase/:planId",
    component: PurchasePage,
    canActivate: [AppAuthGuard],
  },
  {
    path: ":category/product/:product",
    component: ProductPage,
  },
  {
    path: ":category/product/:product/purchase",
    component: PurchasePage,
    canActivate: [AppAuthGuard],
  },
  {
    path: ":category/product/:product/purchase/:planId",
    component: PurchasePage,
    canActivate: [AppAuthGuard],
  },
  {
    path: ":category/:subcategory/product/:product",
    component: ProductPage,
  },
  {
    path: ":category/:subcategory/product/:product/purchase",
    component: PurchasePage,
    canActivate: [AppAuthGuard],
  },
  {
    path: ":category/:subcategory/product/:product/purchase/:planId",
    component: PurchasePage,
    canActivate: [AppAuthGuard],
  },
  { path: ":category/:subcategory", component: CategoryPage },
  { path: ":category", component: CategoryPage },
  {
    path: ":category/:subcategory/:thirdlevelcategory/product/:product",
    component: ProductPage,
  },
  {
    path: ":category/:subcategory/:thirdlevelcategory",
    component: CategoryPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: true,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  providers: [AppAuthGuard],
})
export class AppRoutingModule {}
